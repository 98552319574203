import { SupportedLanguage } from '../common/utils/Constants';

export class CreateOrderRequest {
  firstName: string = '';
  lastName: string = '';
  dateOfBirth: string = '';
  phoneNumber: string = '';
  language: SupportedLanguage = 'English';
  childDateOfBirth: string = '';
  dueDate: string = '';
  street1: string = '';
  street2: string = '';
  city: string = '';
  state: string = '';
  stateValue: string = '';
  zip: string = '';
  providerId: string | null = null;
  providerName: string = '';
  isMedicaid: string = '';
  medicaidInsuranceNumber: string = '';
  insuranceName: string = '';
  insurancePolicyNumber: string = '';
  productId: string = '';
  product?: {
    description: string;
    id: number;
    imageUrl: string;
    make: string;
    model: string;
  } = {
    description: '',
    id: -1,
    imageUrl: '',
    make: '',
    model: '',
  };
  signatureEncodedImg: string = '';
  files: Array<[]> = [];
  isActualBaby: string = '';
  prescriptionAttachments: Array<[]> = [];
  insuranceAttachments: Array<[]> = [];
  physician: string = '';
  notes?: Array<string> = [];
  insuranceId: string = '';
}

export interface CreateOrderProps {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber: string;
  language: SupportedLanguage;
  childDateOfBirth: string;
  dueDate: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  providerId: string | null;
  isMedicaid: string;
  medicaidInsuranceNumber: string;
  insuranceName: string;
  insurancePolicyNumber: string;
  productId: string;
  signatureEncodedImg: string;
  files: Array<[]>;
  notes?: Array<string>;
}
