import React, { FunctionComponent, useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Compressor from 'compressorjs';
import {
  InputAdornment,
  CardContent,
  Grid,
  TextField,
  Typography,
  Card,
  Stack,
  Autocomplete,
  InputLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  TextFieldProps,
  Button,
  CircularProgress,
} from '@mui/material';
import { toast } from 'react-toastify';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useTranslation } from 'react-i18next';
import { CreateOrderStepProps } from '../../CreateOrderStepProps';
import { Provider as ProviderType } from '../../../../models/Provider';
import { getAllProviders } from '../../../../services/OrderService';
import { FileUpload } from '../../../../common/components/FileUpload/FileUpload';
import { CameraModal } from '../../../../common/components/CameraModal/CameraModal';
import { CreateOrderRequest } from '../../../../models/CreateOrderRequest';
import 'react-toastify/dist/ReactToastify.css';

const notifyError = (message: string) => {
  toast.error(message, { position: 'top-center' });
};

interface Files {
  type: string;
  fileName: string;
  encodedImg: string;
  file: File;
  isLoading: boolean;
  index: number;
  isDelete: boolean;
}
const cardStyle = {
  cardMain: {
    boxShadow: 'none',
    overflow: 'auto',
    width: '100%',
    mt: 1.87,
    background: 'transparent',
    '& .Mui-checked': {
      color: '#e6863e !important',
    },
  },
  card: {
    border: '0',
    padding: '10px',
    background: 'transparent',
    boxShadow: 'none !important',
  },
  cardContent: {
    paddingBottom: '24px',
    border: '0px',
    boxShadow: '0 0 10px 0px #ddd',
    borderRadius: '15px',
    background: '#fff',
  },
  uiFormControlRoot: {
    width: '100%',
  },
  inputLabel: {
    color: '#4A6B89',
    fontSize: '14px',
    margin: '0 0 5px ',
    width: '100%',
  },
  textField: {
    border: '0',
    margin: '0',
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#899dad',
      },
      '&:hover fieldset': {
        borderColor: '#899dad',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#899dad ',
      },
    },
  },
  btnStyle: {
    color: 'white',
    backgroundColor: '#4a6a89',
    borderRadius: '5px',
    padding: '8px',
    fontSize: '14px',
    background: '#4a6a89',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#4a6a89',
      boxShadow: 'none',
    },
    display: 'inherit',
    minWidth: { xs: '100%', sm: '64px' },
    '@media (max-width: 850px) and (min-aspect-ratio: 13/9)': {
      minWidth: { sm: '100%' },
    },
    justifyContent: 'center',
  },
  input: {
    color: '#899dad',
    fontSize: '13px',
    backgroundColor: '#F5F6FA',
    fontWeight: '400 ',
    height: '40px ',
    m: 0,
    width: '100%',
    maxWidth: '100%',

    '.MuiAutocomplete-input': {
      padding: '1.5px 4px 7.5px 6px !important',
    },
  },

  bottomText: {
    fontSize: '12px',
    color: '#4a6a89',
    mb: 2,
    my: 2,
  },
  cardConentAttachPre: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    padding: '10px 0 0 0 !important',
    width: '100%',
  },
  fileUploadButton: {
    background: '#4a6a89',
    fontSize: '13px',
    boxShadow: 'none',
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#4a6a89',
      boxShadow: 'none',
    },
  },
  cameraButton: {
    background: '#4a6a89',
    fontSize: '13px',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#4a6a89',
      boxShadow: 'none',
    },
  },
  orLable: {
    display: 'inline-block',
    fontSize: '0',
    background: '#cdd9e2',
    height: '1px',
    position: 'relative',
    top: '-3px',
    left: '8px',
  },
  attachementPreview: {
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    width: '120px',
    height: '120px',
    margin: 0.6,
    '.MuiSvgIcon-root': {
      position: 'absolute',
      right: '3px',
      top: '3px',
      color: 'white',
      background: '#0000007a',
      borderRadius: '3px',
    },
    '.MuiTypography-root': {
      background: '#0000007a',
      color: '#fff',
      position: 'absolute',
      bottom: '7px',
      width: '100%',
      padding: '0px 10px',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
  },
};
const previewImageStyle = {
  width: '100%',
  border: '2px solid #4A6B89',
  borderRadius: '5px',
  height: '100%',
};
const attachmentFileTypes = ['png', 'jpeg', 'jpg', 'pdf'];
export const Provider: FunctionComponent<CreateOrderStepProps> = ({
  onHandleChange,
  formikObj,
}) => {
  const [providers, setProviders] = useState<ProviderType[] | []>([]);
  const [openCamera, setOpenCamera] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [filedName, setFiledName] = useState<string>('');
  const [providerAttachments, setProviderAttachments] = React.useState<any>([]);
  const [insuranceAttachments, setInsuranceAttachments] = React.useState<any>(
    []
  );

  const { t } = useTranslation();

  const onCloseCamera = () => {
    setOpenCamera(false);
  };
  function convertBase64ToFile(base64String: string, fileName: string) {
    const arr = base64String?.split(',');
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n === 0) {
      u8arr[n] = bstr.charCodeAt(n);
      n -= 1;
    }
    return new File([u8arr], fileName, { type: 'image/jpeg' });
  }
  useEffect(() => {
    if (
      formikObj.values.prescriptionAttachments ||
      formikObj.values.insuranceAttachments
    ) {
      setProviderAttachments(formikObj.values.prescriptionAttachments);
      setInsuranceAttachments(formikObj.values.insuranceAttachments);
    } else {
      setProviderAttachments([]);
      setInsuranceAttachments([]);
    }
  }, []);
  const fieldHasError = (field: keyof CreateOrderRequest): boolean =>
    !!formikObj.touched[field] && Boolean(formikObj.errors[field]);

  const getFieldHelperText = (
    field: keyof CreateOrderRequest
  ): string | undefined =>
    formikObj.touched[field] ? formikObj.errors[field] : undefined;

  const getBase64 = (files: Blob) =>
    new Promise((resolve) => {
      let baseURL: string | ArrayBuffer | null = '';
      const reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  const fileUpload = async (index: number, field: string, data: File) => {
    const encodedImage: string | any = await getBase64(data);
    const compress = new Compressor(data, {
      quality: 0.7,
      convertSize: 100,
      convertTypes: ['image/png,image/jpg'],
      success: (compressedResult: File | any) => {
        const compressedImage = new File(
          [compressedResult],
          compressedResult?.name,
          {
            type: compressedResult?.type,
          }
        );
        if (field === 'insurance') {
          insuranceAttachments[index] = {
            ...insuranceAttachments[index],
            isLoading: false,
            encodedImg: encodedImage.split(',')[1],
            file: compressedImage,
          };
          formikObj.setFieldValue(`${field}Attachments`, insuranceAttachments);
        } else {
          providerAttachments[index] = {
            ...providerAttachments[index],
            isLoading: false,
            encodedImg: encodedImage.split(',')[1],
            file: compressedImage,
          };
          formikObj.setFieldValue(`${field}Attachments`, providerAttachments);
        }
      },
      error: () => {
        if (field === 'insurance') {
          insuranceAttachments[index] = {
            ...insuranceAttachments[index],
            isLoading: false,
            encodedImg: encodedImage.split(',')[1],
            file: data,
          };
          formikObj.setFieldValue(`${field}Attachments`, insuranceAttachments);
        } else {
          providerAttachments[index] = {
            ...providerAttachments[index],
            isLoading: false,
            encodedImg: encodedImage.split(',')[1],
            file: data,
          };
          formikObj.setFieldValue(`${field}Attachments`, providerAttachments);
        }
      },
    });
  };
  const imageCapture = async (
    index: number,
    field: string,
    data: File,
    encodedImage: string
  ) => {
    if (field === 'insurance') {
      insuranceAttachments[index] = {
        ...insuranceAttachments[index],
        isLoading: false,
        encodedImg: encodedImage.split(',')[1],
        file: data,
      };
      formikObj.setFieldValue(`${field}Attachments`, insuranceAttachments);
    } else {
      providerAttachments[index] = {
        ...providerAttachments[index],
        isLoading: false,
        encodedImg: encodedImage.split(',')[1],
        file: data,
      };
      formikObj.setFieldValue(`${field}Attachments`, providerAttachments);
    }
  };

  const onSave = async (field: string, data: string) => {
    if (field === 'insurance') {
      insuranceAttachments.push({
        type: field,
        fileName: 'caputre.png',
        encodedImg: '',
        file: data,
        isLoading: true,
        isDelete: false,
        index: insuranceAttachments.length,
      });
      formikObj.setFieldValue(`${field}Attachments`, insuranceAttachments);
      const fileData = await convertBase64ToFile(data, 'capture.png');
      await imageCapture(
        insuranceAttachments.length - 1,
        field,
        fileData,
        data
      );
    } else {
      providerAttachments.push({
        type: field,
        fileName: 'caputre.png',
        encodedImg: '',
        file: data,
        isLoading: true,
        isDelete: false,
        index: providerAttachments.length,
      });
      formikObj.setFieldValue(`${field}Attachments`, providerAttachments);
      const fileData = await convertBase64ToFile(data, 'capture.png');
      await imageCapture(providerAttachments.length - 1, field, fileData, data);
    }
    onCloseCamera();
  };
  const handleTakePhotoChange = async (
    field: 'prescription' | 'insurance',
    files: Array<{}>,
    cb: () => void
  ) => {
    if (!files || !Array.isArray(files) || files.length === 0) {
      // handle error
    }
    Object.values(files).map(async (data: any) => {
      const fileExtension = data.name.split('.').pop();
      const selectableMaxFileSize = 1024 * 1024 * 15;
      if (
        !attachmentFileTypes.includes(fileExtension) ||
        data.size > selectableMaxFileSize
      ) {
        cb();
        if (!attachmentFileTypes.includes(fileExtension)) {
          notifyError(
            t(
              'OutPatient-ProvideInsurance-MedicaidInsuranceImageInvalidFileExtension'
            )
          );
        }
      } else if (field === 'insurance') {
        insuranceAttachments.push({
          type: field,
          fileName: data.name,
          encodedImg: '',
          file: data,
          isLoading: true,
          isDelete: false,
          index: insuranceAttachments.length,
        });
        formikObj.setFieldValue(`${field}Attachments`, insuranceAttachments);
        await fileUpload(insuranceAttachments.length - 1, field, data);
      } else {
        providerAttachments.push({
          type: field,
          fileName: data.name,
          encodedImg: '',
          file: data,
          isLoading: true,
          isDelete: false,
          index: providerAttachments.length,
        });
        formikObj.setFieldValue(`${field}Attachments`, providerAttachments);
        await fileUpload(providerAttachments.length - 1, field, data);
      }
    });
    if (field === 'insurance') {
      formikObj.setFieldValue(`${field}Attachments`, insuranceAttachments);
    } else {
      formikObj.setFieldValue(`${field}Attachments`, providerAttachments);
    }
    onCloseCamera();
    cb();
  };

  const handleTakePicture = (field: string) => {
    setOpenCamera(true);
    setFiledName(field);
  };
  const handleRemoveAttachment = (
    field: 'insurance' | 'prescription',
    index: number
  ) => {
    let allFiles: Array<{}> = [];
    if (field === 'prescription') {
      allFiles = formikObj.values?.prescriptionAttachments;
      allFiles[index] = { ...allFiles[index], isDelete: true };
      providerAttachments[index] = {
        ...providerAttachments[index],
        isDelete: true,
      };
    } else {
      allFiles = formikObj.values?.insuranceAttachments;
      allFiles[index] = { ...allFiles[index], isDelete: true };
      insuranceAttachments[index] = {
        ...insuranceAttachments[index],
        isDelete: true,
      };
    }
    formikObj.setFieldValue(`${field}Attachments`, allFiles);
  };

  const inputFiledProps: TextFieldProps = {
    fullWidth: true,
    size: 'small',
    InputProps: {
      sx: cardStyle.input,
    },
  };
  const getProviders = async () => {
    try {
      const data = await getAllProviders();
      setProviders(data);
      setLoading(false);
    } catch (error) {
      // TODO handle error
    }
  };

  const handleProviderData = async (value: string, label: string) => {
    formikObj.setFieldValue('providerName', label, false);
    formikObj.setFieldValue(`providerId`, value, false);
  };

  useEffect(() => {
    getProviders();
  }, []);
  const details = navigator.userAgent;
  const regExp = /android|iphone/i;
  const isMobileDevice = regExp.test(details);
  return (
    <>
      <Card sx={cardStyle.cardMain}>
        <Card sx={cardStyle.card}>
          <CardContent sx={cardStyle.cardContent}>
            <Typography variant='h6' sx={{ mt: 0, mb: 2 }}>
              {`${t('NewOP-SelectProvider-Doctor')} ${t(
                'Deliveries-Details-DetailsTabTitle'
              )}`}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Stack>
                  <InputLabel sx={cardStyle.inputLabel}>
                    {t('NewOP-SelectProvider-SearchAndSelect')}
                  </InputLabel>

                  <Autocomplete
                    id='selectProvider '
                    disableClearable
                    options={
                      providers &&
                      providers
                        ?.sort((a: any, b: any) => {
                          let name;
                          let bname;
                          if (a.billableName !== null) {
                            name = a.billableName;
                          } else if (
                            a.billableName === null &&
                            a.firstname === ''
                          ) {
                            name = a.lastname;
                          } else {
                            name = a.firstname;
                          }
                          if (b.billableName !== null) {
                            bname = b.billableName;
                          } else if (
                            b.billableName === null &&
                            b.firstname === ''
                          ) {
                            bname = b.lastname;
                          } else {
                            bname = b.firstname;
                          }
                          return name.localeCompare(bname);
                        })
                        .map((option) => ({
                          key: option.id,
                          value: option.id,
                          label:
                            option.billableName !== null
                              ? option.billableName
                              : `${option.firstname} ${option.lastname}`,
                        }))
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option.key} value={option.key}>
                        {option.label}
                      </li>
                    )}
                    noOptionsText={
                      loading ? (
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <CircularProgress
                            sx={{
                              color: '#E6863E',
                              width: '20px !important',
                              height: '20px !important',
                            }}
                          />
                        </Box>
                      ) : (
                        t('NotFound-Title')
                      )
                    }
                    value={formikObj.values.providerName}
                    onChange={(e, val: { value: string; label: string }) =>
                      handleProviderData(val.value, val.label)
                    }
                    onBlur={() =>
                      providers?.filter((option) =>
                        option.billableName !== null
                          ? option.billableName.toLowerCase() ===
                            formikObj.values.providerName?.toLowerCase()
                          : `${option.firstname} ${option.lastname}`.toLowerCase() ===
                            formikObj.values.providerName?.toLowerCase()
                      ).length === 0 &&
                      formikObj.setFieldValue(`providerId`, null, false)
                    }
                    onInputChange={(e, newInputValue: string) => {
                      formikObj.setFieldValue(
                        'providerName',
                        newInputValue,
                        false
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        autoFocus
                        {...params}
                        placeholder={`${t(
                          'OutPatient-SelectProvider-Title'
                        )}...`}
                        {...formikObj.getFieldProps('providerName')}
                        error={fieldHasError('providerName')}
                        helperText={getFieldHelperText('providerName')}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <SearchIcon />
                            </InputAdornment>
                          ),

                          type: 'search',
                          sx: cardStyle.input,
                        }}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Stack>
                  <InputLabel
                    sx={{ ...cardStyle.inputLabel, whiteSpace: 'initial' }}
                  >
                    {t('OutPatient-ProvideInsurance-UploadPrescriptionTitle')}
                  </InputLabel>
                  <Grid container sx={{ mt: 1 }}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      container
                      sx={{
                        alignItems: 'flex-start',
                        flexWrap: 'nowrap',
                        flexDirection: 'column',
                        display: 'flex',
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        container
                        sx={{ alignItems: 'flex-start', flexWrap: 'nowrap' }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          sx={{
                            width: 156,
                            display: { xs: 'none', sm: 'contents' },
                            '@media (max-width: 850px) and (min-aspect-ratio: 13/9)':
                              {
                                display: isMobileDevice ? 'none' : 'contents',
                              },
                          }}
                        >
                          <FileUpload
                            ariaLabelledby='attach-prescription'
                            name='prescriptionAttachments'
                            label={t('UploadPrompt')}
                            onChange={(data: any, cb: () => void) =>
                              handleTakePhotoChange(
                                'prescription',
                                data.target.files,
                                cb
                              )
                            }
                          />
                        </Grid>
                        <Box
                          component='span'
                          sx={{
                            color: '#4a6a89',
                            fontSize: '13px',
                            alignItems: 'flex-start',
                            pt: '15px',
                            display: { xs: 'none', sm: 'flex' },
                            '@media (max-width: 850px) and (min-aspect-ratio: 13/9)':
                              {
                                display: isMobileDevice ? 'none' : 'block',
                              },
                          }}
                        >
                          {t('NewLCOP-OR')}
                        </Box>

                        <Grid
                          component='span'
                          sx={{
                            width: { xs: '100%', sm: 'auto' },
                            display: { xs: 'none', sm: 'block' },
                            '@media (max-width: 850px) and (min-aspect-ratio: 13/9)':
                              {
                                display: isMobileDevice ? 'none' : 'block',
                              },
                          }}
                        >
                          <Button
                            sx={{ minWidth: '100%' }}
                            onClick={() => handleTakePicture('prescription')}
                          >
                            <InputLabel
                              id='prescription'
                              sx={cardStyle.btnStyle}
                            >
                              <CameraAltIcon
                                sx={{ fontSize: '20px', marginRight: '8px' }}
                              />
                              {t('NewLC-CreateOrder-TakePhoto')}
                            </InputLabel>
                          </Button>
                        </Grid>
                        <Grid
                          component='span'
                          sx={{
                            width: { xs: '100%', sm: 'auto' },
                            display: { xs: 'block', sm: 'none' },
                            '@media (max-width: 850px) and (min-aspect-ratio: 13/9)':
                              {
                                display: isMobileDevice ? 'block' : 'none',
                                width: {
                                  sm: isMobileDevice
                                    ? '100% !important'
                                    : 'auto',
                                },
                              },
                          }}
                        >
                          <FileUpload
                            ariaLabelledby='attach-prescription'
                            label={t(
                              'InPatient-AddInPatientOrder-AttachPrescriptionBtn'
                            )}
                            onChange={(
                              e: Event & {
                                target: HTMLInputElement | any;
                              },
                              cb: () => void
                            ) =>
                              handleTakePhotoChange(
                                'prescription',
                                e.target.files,
                                cb
                              )
                            }
                            name={t(
                              'InPatient-AddInPatientOrder-AttachPrescriptionBtn'
                            )}
                          />
                        </Grid>
                      </Grid>
                      {formikObj.touched.prescriptionAttachments &&
                        formikObj.errors.prescriptionAttachments && (
                          <span
                            style={{
                              color: '#d32f2f',
                              fontFamily:
                                'Roboto ,Helvetica, Arial, sans-serif',
                              fontWeight: 400,
                              fontSize: '0.75rem',
                              lineHeight: 1.66,
                              letterSpacing: '0.03333em',
                              textAlign: 'left',
                              marginTop: '3px',
                              marginRight: '14px',
                              marginBottom: 0,
                              marginLeft: '14px',
                            }}
                          >
                            {getFieldHelperText('prescriptionAttachments')}
                          </span>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} container>
                      {formikObj?.values?.prescriptionAttachments &&
                        formikObj?.values?.prescriptionAttachments?.map(
                          (file: Files, index: number) =>
                            file?.isDelete === false && (
                              <Box sx={cardStyle.attachementPreview}>
                                {file?.isLoading === false &&
                                file?.isDelete === false ? (
                                  <>
                                    <DeleteOutlineIcon
                                      onClick={() =>
                                        handleRemoveAttachment(
                                          'prescription',
                                          index
                                        )
                                      }
                                    />
                                    <img
                                      src={`data:image/jpeg;base64,${file?.encodedImg}`}
                                      style={previewImageStyle}
                                      alt={file?.fileName}
                                    />
                                    <Typography
                                      variant='subtitle1'
                                      sx={{ wordBreak: 'break-all' }}
                                    >
                                      {file?.fileName?.length <= 15
                                        ? file?.fileName
                                        : file?.fileName
                                            ?.substring(0, 15)
                                            ?.concat('...')}
                                    </Typography>
                                  </>
                                ) : (
                                  <Box
                                    sx={{
                                      width: '100%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <CircularProgress
                                      sx={{
                                        color: '#E6863E',
                                        width: '20px !important',
                                        height: '20px !important',
                                      }}
                                    />
                                  </Box>
                                )}
                              </Box>
                            )
                        )}
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={cardStyle.card}>
          <CardContent sx={cardStyle.cardContent}>
            <Typography variant='h6' sx={{ mt: 0, mb: 2 }}>
              {t('NewOP-ReviewPage-InsuranceType')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    name='isMedicaid'
                    defaultValue={formikObj.values.isMedicaid}
                    {...formikObj.getFieldProps('isMedicaid')}
                    error={fieldHasError('isMedicaid')}
                    helperText={getFieldHelperText('isMedicaid')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => [
                      formikObj.handleChange({
                        target: {
                          value: e.target.value,
                          name: 'isMedicaid',
                        },
                      }),
                      onHandleChange,
                      formikObj.setFieldValue('productId', '', false),
                      formikObj.setFieldValue(`product`, null, false),
                    ]}
                  >
                    <Grid container>
                      <FormControlLabel
                        value='true'
                        control={<Radio />}
                        label={t('NewOP-Insurance-Medicaid')}
                      />

                      <FormControlLabel
                        value='false'
                        control={<Radio />}
                        label={t('NewOP-Insurance-Commercial')}
                      />
                    </Grid>
                    {formikObj.touched.isMedicaid &&
                      formikObj.errors.isMedicaid && (
                        <span
                          style={{
                            color: '#d32f2f',
                            fontFamily: 'Roboto ,Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            fontSize: '0.75rem',
                            lineHeight: 1.66,
                            letterSpacing: '0.03333em',
                            textAlign: 'left',
                            marginTop: '3px',
                            marginRight: '14px',
                            marginBottom: 0,
                            marginLeft: '14px',
                          }}
                        >
                          {getFieldHelperText('isMedicaid')}
                        </span>
                      )}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={cardStyle.card}>
          <CardContent sx={cardStyle.cardContent}>
            <Typography variant='h6' sx={{ mb: 2 }}>
              {t('NewOP-Insurance-Details')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack
                    sx={{
                      paddingTop: '16px',
                    }}
                  >
                    <InputLabel
                      sx={{ ...cardStyle.inputLabel, whiteSpace: 'initial' }}
                    >
                      {t(
                        'OutPatient-ProvideInsurance-AttachPhotoInsCardPrompt'
                      )}
                    </InputLabel>
                    <Grid container sx={{ mt: 1 }}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        container
                        sx={{
                          alignItems: 'flex-start',
                          flexWrap: 'nowrap',
                          flexDirection: 'column',
                          display: 'flex',
                        }}
                      >
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            sx={{
                              width: 156,
                              display: { xs: 'none', sm: 'contents' },
                              '@media (max-width: 850px) and (min-aspect-ratio: 13/9)':
                                {
                                  display: isMobileDevice ? 'none' : 'contents',
                                },
                            }}
                          >
                            <FileUpload
                              ariaLabelledby='attach-insurance'
                              onChange={(
                                e: Event & { target: HTMLInputElement | any },
                                cb: () => void
                              ) =>
                                handleTakePhotoChange(
                                  'insurance',
                                  e.target.files,
                                  cb
                                )
                              }
                              name='insuranceAttachments'
                              label={t('UploadPrompt')}
                            />
                          </Grid>
                          <Box
                            component='span'
                            sx={{
                              color: '#4a6a89',
                              fontSize: '13px',
                              alignItems: 'flex-start',
                              pt: '15px',
                              display: { xs: 'none', sm: 'flex' },
                              '@media (max-width: 850px) and (min-aspect-ratio: 13/9)':
                                {
                                  display: isMobileDevice ? 'none' : 'flex',
                                },
                            }}
                          >
                            {t('NewLCOP-OR')}
                          </Box>
                          <Grid
                            component='span'
                            sx={{
                              width: { xs: '100%', sm: 'auto' },
                              display: { xs: 'none', sm: 'block' },
                              '@media (max-width: 850px) and (min-aspect-ratio: 13/9)':
                                {
                                  display: isMobileDevice ? 'none' : 'block',
                                },
                            }}
                          >
                            <Button
                              sx={{ minWidth: '100%' }}
                              onClick={() => handleTakePicture('insurance')}
                            >
                              <InputLabel
                                id='insurance'
                                sx={cardStyle.btnStyle}
                              >
                                <CameraAltIcon
                                  sx={{ fontSize: '20px', marginRight: '8px' }}
                                />
                                {t('NewLC-CreateOrder-TakePhoto')}
                              </InputLabel>
                            </Button>
                          </Grid>
                          <Grid
                            component='span'
                            sx={{
                              width: { xs: '100%', sm: 'auto' },
                              display: { xs: 'block', sm: 'none' },
                              '@media (max-width: 850px) and (min-aspect-ratio: 13/9)':
                                {
                                  display: isMobileDevice ? 'block' : 'none',
                                  width: {
                                    sm: isMobileDevice
                                      ? '100% !important'
                                      : 'auto',
                                  },
                                },
                            }}
                          >
                            <FileUpload
                              label={t('NewLC-CreateOrder-TakePhoto')}
                              ariaLabelledby='attach-insurance'
                              onChange={(
                                e: Event & {
                                  target: HTMLInputElement | any;
                                },
                                cb: () => void
                              ) =>
                                handleTakePhotoChange(
                                  'insurance',
                                  e.target.files,
                                  cb
                                )
                              }
                              name={t('NewLC-CreateOrder-TakePhoto')}
                            />
                          </Grid>
                        </Grid>
                        {formikObj.touched.insuranceAttachments &&
                          formikObj.errors.insuranceAttachments && (
                            <span
                              style={{
                                color: '#d32f2f',
                                fontFamily:
                                  'Roboto ,Helvetica, Arial, sans-serif',
                                fontWeight: 400,
                                fontSize: '0.75rem',
                                lineHeight: 1.66,
                                letterSpacing: '0.03333em',
                                textAlign: 'left',
                                marginTop: '3px',
                                marginRight: '14px',
                                marginBottom: 0,
                                marginLeft: '14px',
                              }}
                            >
                              {getFieldHelperText('insuranceAttachments')}
                            </span>
                          )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} container>
                        {formikObj?.values?.insuranceAttachments &&
                          formikObj?.values?.insuranceAttachments?.map(
                            (file: Files, index: number) =>
                              file?.isDelete === false && (
                                <Box sx={cardStyle.attachementPreview}>
                                  {file?.isLoading === false &&
                                  file?.isDelete === false ? (
                                    <>
                                      <DeleteOutlineIcon
                                        onClick={() =>
                                          handleRemoveAttachment(
                                            'insurance',
                                            index
                                          )
                                        }
                                      />
                                      <img
                                        src={`data:image/jpeg;base64,${file?.encodedImg}`}
                                        style={previewImageStyle}
                                        alt={file?.fileName}
                                      />
                                      <Typography
                                        variant='subtitle1'
                                        sx={{ wordBreak: 'break-all' }}
                                      >
                                        {file?.fileName?.length <= 15
                                          ? file?.fileName
                                          : file?.fileName
                                              ?.substring(0, 15)
                                              ?.concat('...')}
                                      </Typography>
                                    </>
                                  ) : (
                                    <Box
                                      sx={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <CircularProgress
                                        sx={{
                                          color: '#E6863E',
                                          width: '20px !important',
                                          height: '20px !important',
                                        }}
                                      />
                                    </Box>
                                  )}
                                </Box>
                              )
                          )}
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
                <Grid sx={{ mt: 2 }}>
                  <Box
                    component='span'
                    sx={{
                      color: '#4a6a89',
                      fontSize: '13px',
                    }}
                  >
                    {t('NewOP-Insurance-OrEnterPrompt')}
                    <Box
                      component='span'
                      sx={{
                        ...cardStyle.orLable,
                        width: { xs: '90%', sm: '40%' },
                      }}
                    >
                      d
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Stack>
                  <InputLabel
                    sx={{ ...cardStyle.inputLabel, whiteSpace: 'initial' }}
                  >
                    {t('NewOP-SelectProvider-InsuranceOrMedicaid')}
                  </InputLabel>
                  <TextField
                    {...inputFiledProps}
                    name='insuranceName'
                    {...formikObj.getFieldProps('insuranceName')}
                    error={fieldHasError('insuranceName')}
                    helperText={getFieldHelperText('insuranceName')}
                    placeholder={t('NewOP-reviewPage-InsuranceName')}
                  />
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{
                  display: 'flex !important',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <InputLabel sx={cardStyle.inputLabel}>
                  {t('PatientInsurance-Edit-PolicyNumberPrompt')}
                </InputLabel>
                <TextField
                  {...inputFiledProps}
                  name='insurancePolicyNumber'
                  {...formikObj.getFieldProps('insurancePolicyNumber')}
                  error={fieldHasError('insurancePolicyNumber')}
                  helperText={getFieldHelperText('insurancePolicyNumber')}
                  placeholder={t('PatientInsurance-Edit-PolicyNumberPrompt')}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Card>
      <CameraModal
        onSave={onSave}
        open={openCamera}
        onClose={onCloseCamera}
        filedName={filedName}
      />
    </>
  );
};
